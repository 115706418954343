<template>
  <OsNotification name="OCD-Notice" :is-fixed="true">
    <h6>Check Finance Eligibility</h6>
    You have added an indicative car valuation to your lease quote and so we’re
    unable to check your eligibility for finance online. Please submit an
    <a class="inline-link" @click="onEnquire"> enquiry </a> to your Showroom who
    will check whether you are likely to be accepted for finance.
  </OsNotification>
</template>

<script lang="ts">
import { NewVehicle, UsedVehicle } from '~/types/vehicle'
import { EFormType } from '~/types/forms'
import { useFormsStore } from '~/stores/forms/formsStore'

export default {
  props: {
    vehicle: {
      type: Object as PropType<NewVehicle | UsedVehicle>,
      required: true
    }
  },
  setup(props) {
    const formsStore = useFormsStore()
    const { showSidepanel } = useSidepanel(ESidepanel.FORMS)

    const onEnquire = () => {
      formsStore.setVehicle(props.vehicle)
      showSidepanel()
      formsStore.updateForm(EFormType.Enquiry)
    }

    return {
      onEnquire
    }
  }
}
</script>

<style lang="scss" scoped>
.notification {
  padding: rem(16);
}
</style>
