import { IStep } from '~/types/steps'
import { EVehicleType } from '~/types/vehicle'
import {
  EFormType,
  ICallToActionDirectoryOption,
  ECallToActionDirectorySteps
} from '~/types/forms'

export const steps: IStep[] = [
  {
    label: ECallToActionDirectorySteps.Directory,
    title: 'What would you like to do?'
  }
]

export const directoryOptions = (
  forms: EFormType[] = [
    EFormType.Offer,
    EFormType.StockNotification,
    EFormType.Enquiry,
    EFormType.TestDrive
  ],
  showDescription: boolean = false
): ICallToActionDirectoryOption[] => {
  return forms.map((formName) => {
    switch (formName) {
      case EFormType.Offer:
        return {
          title: 'Order now',
          summary: `The fastest way to secure your car, in five easy steps${showDescription ? ':' : '.'}`,
          description:
            '<ul class="list-disc"><li>You will need your name and address</li><li>Your Showroom will be in touch to confirm your order</li></ul>',
          conditions: [EVehicleType.New],
          formName: EFormType.Offer
        }
      case EFormType.StockNotification:
        return {
          title: 'Notify me',
          summary:
            'To be notified when this car is back in stock, leave your details and your selected Showroom will be in touch to discuss your preferences.',
          conditions: [EVehicleType.New],
          formName: EFormType.StockNotification
        }
      case EFormType.Enquiry:
        return {
          title: 'Make an Enquiry',
          summary: 'Talk to your Showroom about this car.',
          conditions: [EVehicleType.New, EVehicleType.Used],
          formName: EFormType.Enquiry
        }
      case EFormType.TestDrive:
        return {
          title: 'Request a Test Drive',
          summary: 'Your slot will be confirmed by your Showroom.',
          conditions: [EVehicleType.New, EVehicleType.Used],
          formName: EFormType.TestDrive
        }
      default:
        return {
          title: '',
          summary: '',
          description: '',
          conditions: [],
          formName: '' as EFormType
        }
    }
  })
}

export const directoryFinanceOptions: ICallToActionDirectoryOption[] = [
  {
    title: 'Check Finance Eligibility',
    summary: 'Find out your likelihood of being accepted for finance.',
    description:
      '<ul class="list-disc"><li>This will not affect your credit score</li></ul>',
    conditions: [EVehicleType.New, EVehicleType.Used],
    formName: EFormType.FinanceEligibility
  }
]
