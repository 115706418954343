import { EFormType, ICallToActionDirectoryOption } from '~/types/forms'
import {
  EVehicleType,
  IVehicle,
  NewVehicle,
  UsedVehicle
} from '~/types/vehicle'
import { useFormsStore } from '~/stores/forms/formsStore'

import { ERoutes } from '~/types/routes'
import { useFilterDataStore } from '~/stores/filterDataStore'
import { useStockNotificationStore } from '~/stores/forms/stockNotificationStore'
import { useRetailerStore } from '~/stores/retailerStore'
export const useFormsCta = (
  vehicle: Ref<IVehicle | NewVehicle | UsedVehicle | null>
) => {
  const formsStore = useFormsStore()
  const retailerStore = useRetailerStore()
  const stockNotificatinStore = useStockNotificationStore()
  const filterDataStore = useFilterDataStore()

  const { hideSidepanel } = useSidepanel(ESidepanel.FORMS)

  const router = useRouter()
  const route = useRoute()

  const { conditionSlug } = useVehicle(vehicle)

  const { canBuy, isAdvertiseOnly } = useVehicle(vehicle)
  const {
    isAvailableForFinance,
    isNotificationShowing,
    checkFinanceEligibility
  } = useOcd()

  const showFinance = ref<boolean>(false)
  const directoryButtons = ref<ICallToActionDirectoryOption[]>([])

  const isFormHidden = (formName: EFormType) => {
    if (!formName) return true

    switch (formName) {
      case EFormType.Offer:
        return !vehicle.value || isAdvertiseOnly.value || !canBuy.value
      case EFormType.StockNotification:
        return !vehicle.value || !isAdvertiseOnly.value
      case EFormType.FinanceEligibility:
        return !isAvailableForFinance.value
      default:
        return false
    }
  }

  const activeForms = computed<ICallToActionDirectoryOption[]>(() => {
    const financeOption =
      showFinance.value && !isNotificationShowing.value
        ? directoryFinanceOptions
        : []

    return useCloneDeep(directoryButtons.value.concat(financeOption)).filter(
      (dirOpt) =>
        dirOpt.conditions.includes(
          vehicle.value?.VehicleType || EVehicleType.New
        ) && !isFormHidden(dirOpt.formName)
    )
  })

  const openSelectedForm = async (formName: EFormType): Promise<boolean> => {
    if (!formName || isFormHidden(formName) || !vehicle.value) return false

    switch (formName) {
      case EFormType.Offer:
        hideSidepanel()
        buyVehicle()
        break
      case EFormType.StockNotification:
        hideSidepanel()
        notifyMe()
        break
      case EFormType.FinanceEligibility:
        await checkFinanceEligibility()
        break
    }

    formsStore.updateForm(formName)
    return true
  }

  const notifyMe = () => {
    stockNotificatinStore.setPreSelection({
      models: [...(vehicle.value?.ModelId ? [vehicle.value.ModelId] : [])],
      fuels: [
        ...(vehicle.value?.FuelType &&
        filterDataStore.getFuelIdByFuelType(vehicle.value.FuelType)
          ? [filterDataStore.getFuelIdByFuelType(vehicle.value.FuelType)]
          : [])
      ]
    })

    router.push({
      ...route,
      name: ERoutes.StockNotification
    })
  }

  const { isEnabled } = useFeatureFlag('commission_number_enabled')

  const buyVehicle = () => {
    router.push({
      name: ERoutes.Offer,
      params: {
        type: route.params.type,
        model: vehicle.value!.Model || '',
        id: isEnabled.value
          ? vehicle.value!.CommissionNumber
          : vehicle.value!.Vin,
        retailerId: String(retailerStore.retailer?.Id),
        condition: conditionSlug.value
      }
    })
  }

  return {
    showFinance,
    directoryButtons,
    isFormHidden,
    activeForms,
    openSelectedForm
  }
}
