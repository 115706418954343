<template>
  <transition name="fade">
    <div v-if="localNotifications[name]" class="notification">
      <div class="notification__content">
        <slot />
      </div>
      <OsButton
        v-if="!isFixed"
        class="notification__button"
        :has-shadow="false"
        :has-animation="false"
        aria-label="Ok"
        @click.prevent="close"
      >
        Ok
      </OsButton>
    </div>
  </transition>
</template>

<script lang="ts">
export default {
  name: 'Notification',

  props: {
    name: {
      type: [String, Number],
      required: true
    },
    isFixed: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props) {
    const localNotifications = ref<object>({})

    const storageNotifications = computed(() => {
      return JSON.parse(
        localStorage.getItem('notifications') || `{"${props.name}": true}`
      )
    })

    const updateNotifications = () => {
      localStorage.setItem(
        'notifications',
        JSON.stringify(localNotifications.value)
      )
    }

    const close = () => {
      localNotifications.value[props.name] = false
      updateNotifications()
    }

    onMounted(() => {
      localNotifications.value = storageNotifications.value

      if (localNotifications.value[props.name] === undefined) {
        localNotifications.value[props.name] = true
      }

      updateNotifications()
    })

    return {
      localNotifications,
      close
    }
  }
}
</script>

<style lang="scss">
.notification {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: rem(8) rem(16);
  border-radius: 4px;
  cursor: default;
  width: 100%;
  background-color: $grey--lightest;

  .notification__button {
    min-width: auto;
    height: auto;
    background: transparent;
  }
}

.notification__content {
  font-family: $mb-font-text;
  font-size: rem(14);
  line-height: 1.5;
  color: $grey--darkest;
  a {
    font-size: rem(14);
  }
}
</style>
