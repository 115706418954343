import { EVehicleType } from '~/types/vehicle'

export enum ECallToActionDirectorySteps {
  'Directory' = 'Directory'
}

export interface ICallToActionDirectoryOption {
  title: string
  summary: string
  description?: string
  conditions: EVehicleType[]
  formName: EFormType
  disabled?: boolean
}
